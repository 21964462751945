<template>
  <!-- <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" /> -->
  <div class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden flex justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal titlemenu1="Health Log" titlemenu2="Disease Patterns" />

    <div class="w-full pl-0 pt-8 pb-8 text-left font-proximaMedium">
      <ul
        class="bg-white px-6 py-4 text-left font-proximaLight text-sm text-subheaderText"
      >
        <li>1. Select a pet.</li>
        <li>
          2. Choose health data input type in the modal.
          <ul class="ml-4">
            <li>
              a. To track a disease in real time, use
              <span class="font-bold">symptom tracker</span>.
            </li>
            <li>
              b. To track general health, use
              <span class="font-bold">weekly check</span>.
            </li>
            <li>
              c. To enter a disease pattern over a period of time in the past,
              use <span class="font-bold">past illness</span>.
            </li>
          </ul>
        </li>
        <li>3. Add medication via the floating menu (optional).</li>
      </ul>
      <ul class="mt-4 flex justify-start flex-wrap">
        <li
          class="w-4/12 text-center cursor-pointer"
          v-for="(data, i) in pets"
          v-click-outside="clickoutimg"
          :key="i"
          @click="gotoInputHealthModals(data)"
          @mouseenter="openHealthVisualized(data)"
        >
          <img
            class="m-auto mt-6 rounded-full w-24 h-24"
            :style="{
              border: `4px solid ${data.color} `,
              filter:
                petSelected.petID == data.petID
                  ? 'drop-shadow(10px 10px 10px #0AC0CB)'
                  : '',
            }"
            :src="data.profile_picture"
            :alt="data.callName"
          />
          <span class="pt-2 text-sm font-proximaLight">{{
            data.callName
          }}</span>
        </li>
      </ul>
    </div>
    <BorderedButtonVue
      title="View Disease History"
      borderColor="border-primary"
      textCl="text-primary"
      width="w-10/12"
    />
    <SymptomManagerModalsVue />
    <InputHealthModalsVue
      :propsInputHealthModals="propsInputHealthModals"
      :inputHealthData="inputHealthData"
      @closeInputHealthModals="closeInputHealthModals"
      @openWeeklyCheckModals="openWeeklyCheckModals"
    />
    <WeeklyCheckModalsVue
      :propsWeeklyCheckModals="propsWeeklyCheckModals"
      @closeWeeklyCheckModals="closeWeeklyCheckModals"
    />
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AddIcon from "../icons/AddIcon.vue";
import FormError from "../warning/FormError.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import Loader from "../loader/Loader.vue";
import BorderedButtonVue from "../buttons/BorderedButton.vue";
import SymptomManagerModalsVue from "./modals/SymptomManagerModals.vue";
import InputHealthModalsVue from "./modals/InputHealthModals.vue";
import WeeklyCheckModalsVue from "./modals/WeeklyCheckModals.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AddIcon,
    FormError,
    HeaderGlobal,
    Loader,
    SubmitButton,
    BorderedButtonVue,
    SymptomManagerModalsVue,
    InputHealthModalsVue,
    WeeklyCheckModalsVue,
  },
  data() {
    return {
      showAddpets: false,
      errorMsg: [],
      newpetRoute: "",
      propsInputHealthModals: false,
      propsWeeklyCheckModals: false,
      inputHealthData: "",
      petSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),

    pets() {
      return this.petRecords
        ? this.petRecords.filter((item) => item.type == 2 || item.type == 1)
        : [];
    },
  },

  methods: {
    clickoutimg() {
      this.$store.commit("openNavHealthv", false);

      this.petSelected = {};
    },
    gotoInputHealthModals(i) {
      this.propsInputHealthModals = true;
      this.inputHealthData = i;
      localStorage.setItem("pet", JSON.stringify(i));
    },
    openHealthVisualized(i) {
      this.$store.commit("openNavHealthv", true);
      localStorage.setItem("pet", JSON.stringify(i));
      this.petSelected = i;
    },
    closeInputHealthModals(i) {
      this.propsInputHealthModals = i;
    },
    closeWeeklyCheckModals(i) {
      this.propsWeeklyCheckModals = i;
    },
    openWeeklyCheckModals(i) {
      this.propsWeeklyCheckModals = i;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>