<template>
  <div>
    <!-- symptom manager modals  -->
    <Transition name="modal">
      <div v-if="compSymptomManagerModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="flex flex-col items-center justify-center">
              <img src="@/assets/images/symptom-icon.png" alt="symptom-icon" />
              <h3 class="mt-4 text-2xl font-semibold">
                Manage Symptom Keywords
              </h3>
            </div>
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <div class="w-8/12 relative">
                <input
                  class="w-full px-2 py-1 border-2 border-primary rounded-md"
                  type="text"
                  placeholder="Search for keywords"
                />
                <img
                  class="h-5 absolute top-2 right-3"
                  src="@/assets/images/search-icon.png"
                  alt="search-icon"
                />
              </div>
              <NewTag
                :tags="tagsSymptom"
                placeholder="Add ingredients..."
                @changeTag="changeTagSymptom"
                @deleteTag="deleteTagSymptom"
              />
            </div>
            <submit-button
              :title="reminderBtn[1]"
              :background="reminderBtn[0]"
              width="w-7/12"
              marginT="mt-4"
              @click.native="toggleReminder"
            />
            <div class="mt-4 w-full md:w-8/12 flex justify-evenly">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <BorderedButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_cancel')"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="closeSymptomManagerModals"
                />
              </div>
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_confirm')"
                  background="bg-success"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="openSelectSymptoms"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- symptom manager modals end -->

    <!-- select symptoms modals  -->
    <Transition name="modal">
      <div v-if="selectSymptoms" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <submit-button
                title="Select Symptoms"
                background="bg-primary"
                width="w-8/12"
                @click.native="$router.push('/healthlogoverview/selectsymptom')"
              />
              <div class="w-8/12 mt-4 flex justify-center">
                <ul
                  v-if="tagsSymptom.length"
                  class="px-4 pt-1 pb-2 w-full bg-greyishWhite border border-primary rounded-md flex justify-start flex-wrap"
                >
                  <li
                    v-for="(data, index) in tagsSymptom"
                    :key="index"
                    class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
                  >
                    {{ data }}
                    <div
                      class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
                    >
                      <TrashIcon
                        iconColor="#0AC0CB"
                        @click.native="deleteTagSymptom(data)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="w-8/12 text-left">
                <div class="my-2">
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="weight"
                    >Weight (optional)</label
                  >
                  <input
                    name="weight"
                    class="w-full h-12 px-2 py-1 border-2 border-primary rounded-md"
                    type="text"
                    placeholder="0.000 kg"
                    v-model="weight"
                  />
                </div>
                <div>
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="notes"
                    >Notes (optional)</label
                  >
                  <textarea
                    name="notes"
                    class="w-full h-20 px-2 py-1 border-2 border-primary rounded-md"
                    type="text"
                    placeholder="enter any comments here"
                    v-model="notes"
                  />
                </div>
              </div>
            </div>
            <submit-button
              :title="reminderBtn[1]"
              :background="reminderBtn[0]"
              width="w-7/12"
              marginT="mt-4"
              @click.native="toggleReminder"
            />
            <div class="mt-4 w-full md:w-8/12 flex justify-evenly">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <BorderedButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_cancel')"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="selectSymptoms = false"
                />
              </div>
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_confirm')"
                  background="bg-success"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="goToSelectSymptoms"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- select symptoms modals end -->
  </div>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import NewTag from "../../nutritionplan/nutritionplanpage/NewTag.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";
import Input_ from "../../forms/Input_.vue";
import { activateNotification } from "../../../services/healthLogService";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  props: {
    show: Boolean,
  },
  components: {
    SubmitButton,
    BorderedButton,
    TrashIcon,
    FormError,
    NewTag,
    AddIcon,
    ModalsConfirm,
    InputRadioEach,
    Input_,
  },
  data() {
    return {
      newNutritionModals: false,
      tagsSymptom: [],
      errors: {},
      reminder: false,
      selectSymptoms: false,
      weight: "",
      notes: "",
    };
  },
  computed: {
    compSymptomManagerModals() {
      return this.$store.state.symptomManagerModalsState;
    },
    reminderBtn() {
      return this.reminder
        ? ["bg-primary", "Turn off daily reminder(s)"]
        : ["bg-primary-20", "Turn on daily reminder(s)"];
    },
  },
  methods: {
    emptyField() {
      this.tags = [];
      this.errors = {};
    },
    changeTagSymptom(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.tagsSymptom.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.tagsSymptom.push(line);
        }
      });
    },
    deleteTagSymptom(val) {
      const diags = this.tagsSymptom.filter((c) => c !== val);
      this.tagsSymptom = diags;
    },
    async toggleReminder() {
      this.reminder = !this.reminder;
      const req = {
        name: "SYMPTOM TRACKER", //SYMPTOM TRACKER / WEEKLY CHECK
        frequency: "daily", //daily weekly  monthly quarterly

        petID: this.pet.petID,
      };

      await activateNotification(req, this.reminder);
    },
    closeSymptomManagerModals() {
      this.$store.commit("symptomManagerModals", false);
    },
    openSelectSymptoms() {
      this.selectSymptoms = true;
      this.$store.commit("symptomManagerModals", false);
    },
    goToSelectSymptoms() {
      this.$router.push({ path: "/healthlogoverview/selectsymptom" });
      this.selectSymptoms = false;
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>