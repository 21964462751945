<template>
  <div>
    <!-- input health modals -->
    <Transition name="modal">
      <div v-if="propsInputHealthModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto relative"
          >
            <div
              class="absolute top-3 right-6 text-2xl font-proximaSemibold cursor-pointer"
              @click="closeInputHealthModals"
            >
              ✖
            </div>
            <div class="flex flex-col items-center justify-center">
              <img src="@/assets/images/symptom-icon.png" alt="symptom-icon" />
              <h3 class="mt-4 text-2xl text-subheaderText">
                Input health data about {{ inputHealthData.name }}
              </h3>
            </div>
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  title="Symptom Tracker"
                  background="bg-orange"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="openSymptomTracker"
                />
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  title="Weekly Check"
                  background="bg-primary"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="openWeeklyCheckModals"
                />
                <!-- FIXME: ask why it is not allowed -->
                <SubmitButton
                  class="mx-auto cursor-not-allowed"
                  marginT="mt-2"
                  title="Past Illness"
                  background="bg-lightGrey"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="openPastIllnessModals"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- input health modals end -->

    <!-- past illness modals -->
    <Transition name="modal">
      <div v-if="pastIllnessModalsComp" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <submit-button
                title="Select Symptoms"
                background="bg-primary"
                width="w-8/12"
                @click.native="$router.push('/healthlogoverview/selectsymptom')"
              />
              <div class="w-8/12 mt-4 flex justify-center">
                <ul
                  v-if="tagsSymptom && tagsSymptom.length > 0"
                  class="px-4 pt-1 pb-2 w-full bg-greyishWhite border border-primary rounded-md flex justify-start flex-wrap"
                >
                  <li
                    v-for="(data, index) in tagsSymptom"
                    :key="index"
                    class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
                  >
                    {{ data.name }}
                    <div
                      class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
                    >
                      <TrashIcon
                        iconColor="#0AC0CB"
                        @click.native="deleteTagSymptom(data)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <FormError :errors="errors.tagsSymptom" />
              <div class="w-8/12 text-left">
                <div class="my-2">
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="weight"
                    >Start of Illness</label
                  >
                  <date-picker
                    type="datetime"
                    format="MMM DD, YYYY HH:mm"
                    v-model="startIllness"
                    placeholder="Date/time"
                    class="w-full my-1 text-sm"
                  ></date-picker>
                </div>
                <FormError :errors="errors.startIllness" />
                <div>
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="notes"
                    >End of Illness</label
                  >
                  <date-picker
                    type="datetime"
                    format="MMM DD, YYYY HH:mm"
                    v-model="endIllness"
                    placeholder="Date/time (mandatory)"
                    class="w-full my-1 text-sm"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="mt-4 w-full md:w-8/12 flex justify-evenly">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <BorderedButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_cancel')"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="closePastIllnessModals"
                />
              </div>
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_confirm')"
                  background="bg-success"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="submitConfirmPastIllness"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- past illness modals end -->

    <!-- select symptoms modals  -->
    <Transition name="modal">
      <div v-if="symptomTrackerModalsComp" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <submit-button
                title="Select Symptoms"
                background="bg-primary"
                width="w-8/12"
                @click.native="$router.push('/healthlogoverview/selectsymptom')"
              />
              <div class="w-8/12 mt-4 flex justify-center">
                <ul
                  v-if="tagsSymptom && tagsSymptom.length > 0"
                  class="px-4 pt-1 pb-2 w-full bg-greyishWhite border border-primary rounded-md flex justify-start flex-wrap"
                >
                  <li
                    v-for="(data, index) in tagsSymptom"
                    :key="index"
                    class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
                  >
                    {{ data.name }}
                    <div
                      class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
                    >
                      <TrashIcon
                        iconColor="#0AC0CB"
                        @click.native="deleteTagSymptom(data)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <FormError :errors="errors.tagsSymptom" />
              <div class="w-8/12 text-left">
                <div class="my-2">
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="weight"
                    >Weight (optional)</label
                  >
                  <input
                    name="weight"
                    class="w-full h-12 px-2 py-1 border-2 border-primary rounded-md"
                    type="text"
                    placeholder="0.000 kg"
                    v-model="weight"
                  />
                </div>
                <div>
                  <label
                    class="font-proximaMedium text-sm text-subheaderText"
                    for="notes"
                    >Notes (optional)88</label
                  >
                  <textarea
                    name="notes"
                    class="w-full h-20 px-2 py-1 border-2 border-primary rounded-md"
                    type="text"
                    placeholder="enter any comments here"
                    v-model="notes"
                  />
                </div>
              </div>
            </div>
            <submit-button
              :title="reminderBtn[1]"
              :background="reminderBtn[0]"
              width="w-7/12"
              marginT="mt-4"
              @click.native="toggleReminder"
            />
            <div class="mt-4 w-full md:w-8/12 flex justify-evenly">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <BorderedButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_cancel')"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="closeSymptomTrackerModals"
                />
              </div>
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_confirm')"
                  background="bg-success"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="submitConfirm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- select symptoms modals end -->
  </div>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import FormError from "../../warning/FormError.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { activateNotification } from "../../../services/healthLogService";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import "vue2-datepicker/index.css";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["propsInputHealthModals", "inputHealthData"],
  components: {
    SubmitButton,
    BorderedButton,
    DatePicker,
    TrashIcon,
    FormError,
  },
  data() {
    return {
      pet: {},
      tagsSymptom: [],
      pastIllnessModals: false,

      reminder: false,
      startIllness: null,
      endIllness: null,
      weight: "",
      notes: "",
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      prevUsedCustomSymptom: "healthlog/getPrevUsedCustomSymptom",
    }),

    reminderBtn() {
      return this.reminder
        ? ["bg-primary", "Turn off daily reminder(s)"]
        : ["bg-primary-20", "Turn on daily reminder(s)"];
    },
    symptomTrackerModalsComp() {
      return this.$store.state.symptomTrackerModalsState;
    },
    pastIllnessModalsComp() {
      return this.$store.state.pastIllnessModalsState;
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    console.log("nnnnn : ", this.pet);
    console.log(
      "------",
      this.$store.state.healthlog.prevUsedCustomSymptom,
    );
    this.getPrevUsedCustomSymptom(this.pet.petID);
    if (this.$store.state.healthlog.prevUsedCustomSymptom.length > 0) {
      console.log(this.$store.state.healthlog.prevUsedCustomSymptom)
      this.tagsSymptom = this.$store.state.healthlog.prevUsedCustomSymptom;
    }
    if (this.$route.params.data) {
      this.tagsSymptom = [...this.tagsSymptom, this.$route.params.data];
    }
  },
  methods: {
    ...mapActions({
      addSymptomTracker: "healthlog/addSymptomTracker",
      addPastIllness: "healthlog/addPastIllness",
      getPrevUsedCustomSymptom: "healthlog/getPrevUsedCustomSymptom",
    }),
    closeInputHealthModals() {
      this.$emit("closeInputHealthModals", false);
    },
    closeSymptomTrackerModals() {
      /*  FIXME:  reset fields */
      this.$store.commit("symptomTrackerModals", false);
    },
    closePastIllnessModals() {
      /*  FIXME:  reset fields */
      this.$store.commit("pastIllnessModals", false);
    },
    openPastIllnessModals() {
      this.$store.commit("pastIllnessModals", true);
      this.$emit("closeInputHealthModals", false);
    },
    deleteTagSymptom(val) {
      const diags = this.tagsSymptom.filter((c) => c !== val);
      this.tagsSymptom = diags;
    },
    validate() {
      const errors = {};

      if (this.tagsSymptom.length == 0)
        errors.tagsSymptom = "Please select at least one symptom";

      return Object.keys(errors).length === 0 ? null : errors;
    },
    submitConfirm() {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      //goToSelectSymptoms
      var symptoms = [];
      this.tagsSymptom.map((item) => {
        symptoms.push(item.id);
      });
      const req = {
        symptoms: symptoms,
        custom_symptoms: [],
        notes: this.notes,
        weight: this.weight,
        petID: this.pet.petID,
      };

      console.log(req);
      this.addSymptomTracker(req);
      //this.pastIllnessModals = false;
      /*  FIXME:  reset fields */
    },
    validatePastIllness() {
      const errors = {};

      if (this.startIllness == null)
        /* FIXME: check if start date is less then today */
        errors.startIllness = "start date of the illness is required";
      /* FIXME: check if end date is less then today */
      /* FIXME: end date must be greather then start date */
      if (this.tagsSymptom.length == 0)
        errors.tagsSymptom = "Please select at least one symptom";

      return Object.keys(errors).length === 0 ? null : errors;
    },
    submitConfirmPastIllness() {
      const errors = this.validatePastIllness();
      this.errors = errors || {};
      if (errors) return;

      //goToSelectSymptoms
      var symptoms = [];
      this.tagsSymptom.map((item) => {
        symptoms.push(item.id);
      });
      const req = {
        symptoms: symptoms,
        custom_symptoms: [],
        start_illness: this.startIllness,
        end_illness: this.endIllness,
        petID: this.pet.petID,
      };

      console.log(req);
      this.addPastIllness(req);
      /*  FIXME:  reset fields */
    },
    goToSelectSymptoms() {
      this.$router.push({ path: "/healthlogoverview/selectsymptom" });
      this.$store.commit("pastIllnessModals", false);
      this.$store.commit("symptomTrackerModals", false);
    },
    openSymptomTracker() {
      this.$emit("closeInputHealthModals", false);
      this.$store.commit("symptomTrackerModals", true);
    },
    async toggleReminder() {
      this.reminder = !this.reminder;
      const req = {
        name: "SYMPTOM TRACKER", //SYMPTOM TRACKER / WEEKLY CHECK
        frequency: "daily", //daily weekly  monthly quarterly

        petID: this.pet.petID,
      };

      await activateNotification(req, this.reminder);
    },
    openWeeklyCheckModals() {
      this.$emit("closeInputHealthModals", false);
      this.$emit("openWeeklyCheckModals", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<style>
.mx-datepicker {
  width: 100%;
}
.mx-input {
  height: 50px;
  width: 100%;
  border: 0;
  background: rgba(156, 163, 175, 0.2);
  border-radius: 6px;
  box-shadow: none;
}
.mx-datepicker-popup {
  z-index: 9999;
}
</style>