<template>
  <div>
    <!-- input health modals -->
    <Transition name="modal">
      <div v-if="propsWeeklyCheckModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto relative"
          >
            <div
              class="absolute top-3 right-6 text-2xl font-proximaSemibold cursor-pointer"
              @click="closeWeeklyCheckModals"
            >
              ✖
            </div>
            <div
              class="w-full text-left mt-8 flex flex-col items-center justify-center"
            >
              <div class="w-8/12 mb-2 font-proximaLight text-subheaderText">
                How's the poop?
              </div>
              <div
                class="mx-auto w-8/12 flex flex-wrap flex-row justify-between items-start relative"
              >
                <SubmitButton
                  class="mx-1 border-4 border-transparent"
                  :class="[
                    data.shadow,
                    {
                      'border-4 border-primary text-primary':
                        highlightedIndex === i,
                    },
                  ]"
                  marginT="mt-2"
                  :title="data.title"
                  :background="data.color"
                  :textColor="data.text"
                  paddingX="px-6"
                  width="w-5/12"
                  @click.native="toggleHighlight(i, data.name)"
                  v-for="(data, i) in poopColors"
                  :key="i"
                />
              </div>
              <div class="w-8/12">
                <h5 class="mt-8 font-proximaLight text-base text-subheaderText">
                  Anything else of significance (optional)?
                </h5>
                <div
                  class="my-2 flex font-proximaLight text-base text-subheaderText"
                >
                  <div class="w-6/12">
                    <InputRadioEachVue
                      @inputVal="inputValSignificance"
                      name="significance"
                      title="soft / watery"
                      :checked="1 == 1"
                    />
                    <InputRadioEachVue
                      @inputVal="inputValSignificance"
                      name="significance"
                      title="large size"
                    />
                  </div>
                  <div class="w-6/12">
                    <InputRadioEachVue
                      @inputVal="inputValSignificance"
                      name="significance"
                      title="mucus coating"
                    />
                    <InputRadioEachVue
                      @inputVal="inputValSignificance"
                      name="significance"
                      title="hard / pebbles"
                    />
                  </div>
                </div>
                <div class="my-2">
                  <label
                    class="font-proximaLight text-base text-subheaderText"
                    for="weight"
                    >Weight (optional)</label
                  >
                  <input
                    name="weight"
                    class="w-full mt-2 h-12 px-2 py-1 border-2 border-primary rounded-md"
                    type="text"
                    placeholder="0.000 kg"
                    v-model="weight"
                  />
                </div>
              </div>
            </div>
            <div class="w-8/12">
              <submit-button
                :title="reminderBtn[1]"
                :background="reminderBtn[0]"
                marginT="mt-4"
                @click.native="toggleReminder"
              />
            </div>
            <div class="mt-4 w-full md:w-8/12 flex justify-evenly">
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <BorderedButton
                  class="mx-auto"
                  marginT="mt-2"
                  :title="$t('global_cancel')"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="closeWeeklyCheckModals"
                />
              </div>
              <div class="mx-auto w-10/12 md:w-5/12 relative">
                <SubmitButton
                  class="mx-auto"
                  marginT="mt-2"
                  title="Next"
                  background="bg-success"
                  paddingX="px-6"
                  width="w-full"
                  @click.native="openSideViewModals"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- input health modals end -->

    <!-- side view body condition modals -->
    <Transition name="modal">
      <div v-if="sideViewModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <div class="w-8/12 mt-4 flex justify-center">
                <div>
                  <h1 class="mt-4 text-2xl text-subheaderText">
                    Side View of Body Condition
                  </h1>
                  <p class="mt-4 font-proximaLight text-lg text-subheaderText">
                    This information will be visible on Rocky’s medical history.
                  </p>
                  <img
                    src="@/assets/images/dog-images/dog-sideview-lg.png"
                    alt="dog-sideview"
                    class="m-auto h-44"
                  />
                </div>
              </div>
            </div>
            <div class="w-8/12 mt-4 pb-6 flex justify-center items-center">
              <input
                class=""
                ref="file"
                type="file"
                @change="fileName"
                hidden
              />
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                title="Skip"
                paddingX="px-6"
                width="w-full"
                @click.native="skipSideViewModals"
              />
              <IconButton
                component="CameraIcon"
                iconColor="#fff"
                title="Take Photo"
                background="bg-primary"
                paddingX="px-6"
                width="w-full"
                class="ml-2"
                @click.native="openFiles()"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- side view body condition modals end -->

    <!-- attach side view modals  -->
    <Transition name="modal">
      <div v-if="attachSideViewModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto relative"
          >
            <div
              class="w-full mt-8 flex flex-col items-center justify-center relative"
            >
              <div
                class="absolute -top-10 right-0 text-2xl font-proximaSemibold cursor-pointer"
                @click="removeImg()"
              >
                ✖
              </div>
              <div class="w-8/12 mt-4 flex flex-col justify-center">
                <img :src="imgUrl" :alt="imgUrl" />
                <h1 class="my-4 text-2xl">Attach Photo?</h1>
              </div>
            </div>
            <div class="w-8/12 mt-4 pb-6 flex justify-center items-center">
              <input
                class=""
                ref="file"
                type="file"
                @change="fileName"
                hidden
              />
              <BorderedButton
                class="mr-1"
                marginT="mt-2"
                title="Retake"
                paddingX="px-6"
                width="w-6/12"
                @click.native="openFiles()"
              />
              <SubmitButton
                class="ml-1"
                marginT="mt-2"
                title="Confirm"
                background="bg-success"
                paddingX="px-6"
                width="w-6/12"
                @click.native="openBirdViewModals"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- attach side view modals end -->

    <!-- bird view body condition modals -->
    <Transition name="modal">
      <div v-if="birdViewModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
          >
            <div class="w-full mt-8 flex flex-col items-center justify-center">
              <div class="w-8/12 mt-4 flex justify-center">
                <div>
                  <h1 class="mt-4 text-2xl text-subheaderText">
                    Bird’s Eye View of Body Condition
                  </h1>
                  <p class="mt-4 font-proximaLight text-lg text-subheaderText">
                    This information will be visible on Rocky’s medical history.
                  </p>
                  <img
                    src="@/assets/images/dog-images/dog-birdview-lg.png"
                    alt="dog-sideview"
                    class="m-auto h-44"
                  />
                </div>
              </div>
            </div>
            <div class="w-8/12 mt-4 pb-6 flex justify-center items-center">
              <input
                class=""
                ref="file"
                type="file"
                @change="fileNameBirdView"
                hidden
              />
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                title="Skip"
                paddingX="px-6"
                width="w-full"
                @click.native="skipBirdViewModals"
              />
              <IconButton
                component="CameraIcon"
                iconColor="#fff"
                title="Take Photo"
                background="bg-primary"
                paddingX="px-6"
                width="w-full"
                class="ml-2"
                @click.native="openFilesBirdView()"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- bird view body condition modals end -->

    <!-- attach bird view modals  -->
    <Transition name="modal">
      <div v-if="attachBirdViewModals" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto relative"
          >
            <div
              class="w-full mt-8 flex flex-col items-center justify-center relative"
            >
              <div
                class="absolute -top-10 right-0 text-2xl font-proximaSemibold cursor-pointer"
                @click="closeAttachBirdViewModals"
              >
                ✖
              </div>
              <div class="w-8/12 mt-4 flex flex-col justify-center">
                <img :src="imgUrlBirdView" :alt="imgUrlBirdView" />
                <h1 class="my-4 text-2xl">Attach Photo?</h1>
              </div>
            </div>
            <div class="w-8/12 mt-4 pb-6 flex justify-center items-center">
              <input
                class=""
                ref="file"
                type="file"
                @change="fileNameBirdView"
                hidden
              />
              <BorderedButton
                class="mr-1"
                marginT="mt-2"
                title="Retake"
                paddingX="px-6"
                width="w-6/12"
                @click.native="openFilesBirdView()"
              />
              <SubmitButton
                class="ml-1"
                marginT="mt-2"
                title="Confirm"
                background="bg-success"
                paddingX="px-6"
                width="w-6/12"
                @click.native="submitWeeklyCheck"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- attach bird view modals end -->
  </div>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import InputRadioEachVue from "../../forms/InputRadioEach.vue";
import IconButton from "../../buttons/IconButton.vue";
import { activateNotification } from "../../../services/healthLogService";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  props: ["propsWeeklyCheckModals"],
  components: {
    SubmitButton,
    BorderedButton,
    DatePicker,
    InputRadioEachVue,
    IconButton,
  },
  data() {
    return {
      pet: {},
      tagsSymptom: [],
      pastIllnessModals: false,
      symptomTrackerModals: false,
      sideViewModals: false,
      attachSideViewModals: false,
      birdViewModals: false,
      attachBirdViewModals: false,
      reminder: false,
      weight: "",
      poopColors: [
        {
          id: "1",
          shadow: "",
          text: "text-white",
          color: "bg-brown",
          title: "Brown",
          name: "brown",
          highlight: true,
        },
        {
          id: "2",
          shadow: "shadow",
          text: "text-subheadertext",
          color: "bg-white",
          title: "White Spots",
          name: "white_spots",
          highlight: false,
        },
        {
          id: "3",
          shadow: "",
          text: "text-white",
          color: "bg-black",
          title: "Black",
          name: "black",
          highlight: false,
        },
        {
          id: "4",
          shadow: "",
          text: "text-white",
          color: "bg-red",
          title: "Red Streaks",
          name: "red_streaks",
          highlight: false,
        },
        {
          id: "5",
          shadow: "",
          text: "text-white",
          color: "bg-forestGreen",
          title: "Green",
          name: "green",
          highlight: false,
        },
        {
          id: "6",
          shadow: "",
          text: "text-white",
          color: "bg-mediumGrey",
          title: "Grey / Greasy",
          name: "grey_greasy",
          highlight: false,
        },
        {
          id: "7",
          shadow: "",
          text: "text-white",
          color: "bg-orange",
          title: "Orange",
          name: "orange",
          highlight: false,
        },
      ],
      highlightedIndex: 0,
      significanceOpt: "soft_watery",
      howsPoop: "brown",
      imgUrl: "",
      uploadedFileName: "",
      side_picture: null,
      imgUrlBirdView: "",
      uploadedFileNameBirdView: "",
      top_picture: null,
    };
  },

  computed: {
    reminderBtn() {
      return this.reminder
        ? ["bg-primary", "Turn off daily reminder(s)"]
        : ["bg-primary-20", "Turn on daily reminder(s)"];
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    this.highlightedIndex = 0;
    this.howsPoop = "brown";
    this.significanceOpt = "soft_watery";
    this.weight = "";
  },
  methods: {
    ...mapActions({
      addWeeklyCheck: "healthlog/addWeeklyCheck",
    }),
    closeWeeklyCheckModals() {
      this.$emit("closeWeeklyCheckModals", false);
      //---- init data -----
    },
    openSideViewModals() {
      this.sideViewModals = true;
      this.closeWeeklyCheckModals();
    },
    openBirdViewModals() {
      this.attachSideViewModals = false;
      this.birdViewModals = true;
    },
    skipSideViewModals() {
      this.sideViewModals = false;
      this.birdViewModals = true;
    },
    skipBirdViewModals() {
      this.submitWeeklyCheck();
      this.birdViewModals = false;
      this.removeImg();
    },
    closeAttachBirdViewModals() {
      this.attachBirdViewModals = false;
      this.removeImg();
      this.removeImgBirdView();
    },
    async toggleReminder() {
      this.reminder = !this.reminder;
      const req = {
        name: "WEEKLY CHECK", //SYMPTOM TRACKER / WEEKLY CHECK
        frequency: "daily", //daily weekly  monthly quarterly

        petID: this.pet.petID,
      };

      await activateNotification(req, this.reminder);
    },
    toggleHighlight(index, data) {
      console.log(data);
      this.highlightedIndex = index;
      this.howsPoop = data;
    },
    inputValSignificance(i) {
      this.significanceOpt =
        i == "soft / watery"
          ? "soft_watery"
          : "hard / pebbles"
          ? "hard_pebbles"
          : "mucus coating"
          ? "mucus_coating"
          : "large_size";
    },
    removeImg() {
      this.uploadedFileName = "";
      this.imgUrl = "";
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.files[0].name;
      const file = e.target.files[0];
      this.side_picture = file;
      this.imgUrl = URL.createObjectURL(file);
      this.sideViewModals = false;
      this.attachSideViewModals = true;
    },
    removeImgBirdView() {
      this.removeImg();
      this.uploadedFileNameBirdView = "";
      this.imgUrlBirdView = "";
    },
    openFilesBirdView() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileNameBirdView(e) {
      this.uploadedFileNameBirdView = e.target.files[0].name;
      const file = e.target.files[0];
      this.top_picture = file;
      this.imgUrlBirdView = URL.createObjectURL(file);
      this.birdViewModals = false;
      this.attachBirdViewModals = true;
    },
    submitWeeklyCheck() {
      let req = {
        top_view: this.top_picture,
        side_view: this.side_picture,
        poop: this.howsPoop,
        other_significance: this.significanceOpt,
        weight: this.weight,
        petID: this.pet.petID,
      };
      console.log(req);
      this.addWeeklyCheck(req);

      //this.closeAttachBirdViewModals();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<style>
.mx-datepicker {
  width: 100%;
}
.mx-input {
  height: 50px;
  width: 100%;
  border: 0;
  background: rgba(156, 163, 175, 0.2);
  border-radius: 6px;
  box-shadow: none;
}
.mx-datepicker-popup {
  z-index: 9999;
}
</style>