<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_37464_31577)">
      <path
        d="M22.735 27.305H18.75V24.725H22.735C23.263 24.7247 23.7693 24.5148 24.1427 24.1414C24.516 23.7681 24.7259 23.2618 24.7262 22.7337V18.75H27.305V22.735C27.3037 23.9468 26.8216 25.1087 25.9646 25.9655C25.1075 26.8222 23.9456 27.304 22.7337 27.305H22.735Z"
        :fill="iconColor"
      />
      <path
        d="M27.305 11.2503H24.725V7.26531C24.7247 6.7373 24.5148 6.23101 24.1414 5.85765C23.7681 5.48429 23.2618 5.27439 22.7337 5.27406H18.75V2.69531H22.735C23.9466 2.69664 25.1083 3.17854 25.965 4.0353C26.8218 4.89205 27.3037 6.05368 27.305 7.26531V11.2503Z"
        :fill="iconColor"
      />
      <path
        d="M11.2503 27.305H7.26531C6.05346 27.3037 4.89165 26.8216 4.03486 25.9646C3.17807 25.1075 2.69631 23.9456 2.69531 22.7337V18.75H5.27531V22.735C5.27564 23.263 5.48554 23.7693 5.8589 24.1427C6.23226 24.516 6.73855 24.7259 7.26656 24.7262H11.2503V27.305Z"
        :fill="iconColor"
      />
      <path
        d="M5.27406 11.2503H2.69531V7.26531C2.69664 6.05368 3.17854 4.89205 4.0353 4.0353C4.89205 3.17854 6.05368 2.69664 7.26531 2.69531H11.2503V5.27531H7.26531C6.73773 5.27597 6.23195 5.48585 5.8589 5.8589C5.48585 6.23195 5.27597 6.73773 5.27531 7.26531V11.2503H5.27406Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_37464_31577">
        <rect width="30" height="30" :fill="iconColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>