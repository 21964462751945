<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.63428 5.65349C10.4436 6.46198 10.8991 7.55845 10.9011 8.70238C10.903 9.84632 10.4511 10.9443 9.64456 11.7555L9.63428 11.7661L7.7235 13.6769C6.90836 14.4643 5.81654 14.9001 4.68322 14.8903C3.5499 14.8805 2.46577 14.4259 1.66436 13.6245C0.862951 12.8231 0.408392 11.739 0.398599 10.6056C0.388806 9.47232 0.824561 8.3805 1.612 7.56537L2.66664 6.51073C2.72899 6.44818 2.80821 6.40515 2.89462 6.38689C2.98102 6.36864 3.07088 6.37595 3.1532 6.40793C3.23552 6.43991 3.30674 6.49518 3.35816 6.56698C3.40958 6.63878 3.43897 6.72401 3.44274 6.81225C3.46102 7.32274 3.55386 7.82782 3.7183 8.31145C3.74628 8.39243 3.75098 8.47964 3.73186 8.56315C3.71274 8.64667 3.67056 8.72314 3.61014 8.78388L3.23804 9.15597C3.04187 9.34374 2.88502 9.56866 2.77661 9.81762C2.6682 10.0666 2.6104 10.3346 2.60657 10.6061C2.60274 10.8777 2.65297 11.1472 2.75432 11.3992C2.85567 11.6511 3.00613 11.8803 3.19692 12.0735C3.38772 12.2668 3.61505 12.4201 3.86567 12.5246C4.11629 12.6291 4.38521 12.6827 4.65675 12.6823C4.92829 12.6819 5.19705 12.6275 5.44736 12.5223C5.69767 12.417 5.92454 12.263 6.11477 12.0692L8.02664 10.1587C8.21686 9.96861 8.36775 9.74291 8.47071 9.49448C8.57366 9.24606 8.62665 8.97978 8.62665 8.71086C8.62665 8.44195 8.57366 8.17567 8.47071 7.92725C8.36775 7.67882 8.21686 7.45312 8.02664 7.26303C7.93613 7.17328 7.83776 7.09183 7.73269 7.01966C7.67411 6.97941 7.62576 6.92602 7.59148 6.86377C7.55721 6.80151 7.53796 6.7321 7.53528 6.66108C7.52907 6.50449 7.55543 6.34831 7.61268 6.20243C7.66993 6.05655 7.75684 5.92414 7.8679 5.81358L8.46661 5.21487C8.54282 5.13911 8.64329 5.09264 8.75038 5.08364C8.85746 5.07464 8.96428 5.10368 9.05207 5.16566C9.26055 5.31121 9.45582 5.47481 9.63564 5.65457L9.63428 5.65349Z"
      :fill="iconColor"
    />
    <path
      d="M13.6466 1.64638C12.8355 0.837225 11.7365 0.382813 10.5908 0.382812C9.44514 0.382812 8.34622 0.837225 7.5351 1.64638L5.62431 3.55717L5.61404 3.56771C5.16988 4.01525 4.82899 4.55454 4.61528 5.14774C4.40156 5.74095 4.3202 6.37373 4.37688 7.00171C4.43356 7.62969 4.62693 8.23767 4.94341 8.78302C5.2599 9.32837 5.69184 9.79789 6.20896 10.1587C6.29676 10.2206 6.40358 10.2496 6.51064 10.2406C6.61771 10.2316 6.71818 10.1852 6.79442 10.1094L7.39313 9.51074C7.50419 9.40017 7.5911 9.26776 7.64835 9.12188C7.7056 8.976 7.73196 8.81983 7.72574 8.66324C7.72306 8.59222 7.70382 8.52281 7.66954 8.46055C7.63527 8.39829 7.58691 8.34491 7.52834 8.30466C7.42328 8.23249 7.3249 8.15104 7.23439 8.06129C7.04418 7.8712 6.89328 7.6455 6.79032 7.39707C6.68737 7.14865 6.63438 6.88237 6.63438 6.61346C6.63438 6.34454 6.68737 6.07826 6.79032 5.82984C6.89328 5.58141 7.04418 5.35571 7.23439 5.16563L9.14518 3.25511C9.3347 3.05802 9.56178 2.90089 9.81302 2.79299C10.0643 2.6851 10.3346 2.62861 10.608 2.62688C10.8814 2.62515 11.1524 2.67821 11.405 2.78292C11.6576 2.88763 11.8866 3.04187 12.0786 3.23654C12.2706 3.43122 12.4217 3.66237 12.5229 3.91638C12.6241 4.17038 12.6734 4.44209 12.6679 4.71546C12.6624 4.98883 12.6021 5.25833 12.4908 5.50804C12.3794 5.75776 12.2192 5.98265 12.0195 6.16943L11.6471 6.54098C11.5867 6.6017 11.5445 6.67818 11.5253 6.7617C11.5062 6.84522 11.5109 6.93243 11.5389 7.01341C11.7034 7.49704 11.7962 8.00212 11.8145 8.51262C11.8183 8.60085 11.8477 8.68608 11.8991 8.75788C11.9505 8.82968 12.0217 8.88495 12.104 8.91693C12.1864 8.94892 12.2762 8.95623 12.3626 8.93797C12.449 8.91971 12.5283 8.87668 12.5906 8.81413L13.6452 7.7595C14.4544 6.94838 14.9088 5.84945 14.9088 4.70375C14.9088 3.55805 14.4544 2.45911 13.6452 1.648L13.6466 1.64638Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>