var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-center items-center"},[_vm._m(0),_c('HeaderGlobal',{attrs:{"titlemenu1":"Health Log","titlemenu2":"Disease Patterns"}}),_c('div',{staticClass:"w-full pl-0 pt-8 pb-8 text-left font-proximaMedium"},[_vm._m(1),_c('ul',{staticClass:"mt-4 flex justify-start flex-wrap"},_vm._l((_vm.pets),function(data,i){return _c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickoutimg),expression:"clickoutimg"}],key:i,staticClass:"w-4/12 text-center cursor-pointer",on:{"click":function($event){return _vm.gotoInputHealthModals(data)},"mouseenter":function($event){return _vm.openHealthVisualized(data)}}},[_c('img',{staticClass:"m-auto mt-6 rounded-full w-24 h-24",style:({
            border: `4px solid ${data.color} `,
            filter:
              _vm.petSelected.petID == data.petID
                ? 'drop-shadow(10px 10px 10px #0AC0CB)'
                : '',
          }),attrs:{"src":data.profile_picture,"alt":data.callName}}),_c('span',{staticClass:"pt-2 text-sm font-proximaLight"},[_vm._v(_vm._s(data.callName))])])}),0)]),_c('BorderedButtonVue',{attrs:{"title":"View Disease History","borderColor":"border-primary","textCl":"text-primary","width":"w-10/12"}}),_c('SymptomManagerModalsVue'),_c('InputHealthModalsVue',{attrs:{"propsInputHealthModals":_vm.propsInputHealthModals,"inputHealthData":_vm.inputHealthData},on:{"closeInputHealthModals":_vm.closeInputHealthModals,"openWeeklyCheckModals":_vm.openWeeklyCheckModals}}),_c('WeeklyCheckModalsVue',{attrs:{"propsWeeklyCheckModals":_vm.propsWeeklyCheckModals},on:{"closeWeeklyCheckModals":_vm.closeWeeklyCheckModals}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6 w-full hidden md:block lg:hidden flex justify-center items-center"},[_c('img',{staticClass:"h-72 m-auto",attrs:{"src":require("@/assets/images/perpetual-logo.svg"),"alt":"perpetual-logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"bg-white px-6 py-4 text-left font-proximaLight text-sm text-subheaderText"},[_c('li',[_vm._v("1. Select a pet.")]),_c('li',[_vm._v(" 2. Choose health data input type in the modal. "),_c('ul',{staticClass:"ml-4"},[_c('li',[_vm._v(" a. To track a disease in real time, use "),_c('span',{staticClass:"font-bold"},[_vm._v("symptom tracker")]),_vm._v(". ")]),_c('li',[_vm._v(" b. To track general health, use "),_c('span',{staticClass:"font-bold"},[_vm._v("weekly check")]),_vm._v(". ")]),_c('li',[_vm._v(" c. To enter a disease pattern over a period of time in the past, use "),_c('span',{staticClass:"font-bold"},[_vm._v("past illness")]),_vm._v(". ")])])]),_c('li',[_vm._v("3. Add medication via the floating menu (optional).")])])
}]

export { render, staticRenderFns }