<template>
  <div class="mt-4 w-11/12 md:w-8/12 text-left font-proximaMedium text-sm">
    <input
      @input="changeTag"
      :placeholder="placeholder"
      class="w-full p-2 border-2 border-primary rounded-md m-auto"
    />
    <div class="mt-4 flex justify-center">
      <ul
        v-if="tags.length"
        class="px-4 pt-1 pb-2 w-full bg-greyishWhite border border-primary rounded-md flex justify-start flex-wrap"
      >
        <li
          v-for="(data, index) in tags"
          :key="index"
          class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
        >
          {{ data }}
          <div
            class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
          >
            <TrashIcon iconColor="#0AC0CB" @click.native="deleteTag(data)" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import TrashIcon from "@/components/icons/TrashIcon.vue";

export default {
  props: {
    tags: [],
    placeholder: String,
  },
  components: {
    TrashIcon,
  },
  data() {
    return {};
  },
  methods: {
    changeTag(e) {
      this.$emit("changeTag", e.target.value);
    },
    deleteTag(val) {
      this.$emit("deleteTag", val);
    },
  },
};
</script>
  
  <style></style>
  