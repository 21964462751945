<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.54692 0.59375H0.0078125V8.13286H7.54692V0.59375ZM6.2904 6.87634H1.26433V1.85027H6.2904V6.87634Z"
      :fill="iconColor"
    />
    <path d="M2.51562 3.10547H5.02866V5.6185H2.51562V3.10547Z" :fill="iconColor" />
    <path
      d="M0.0078125 20.6993H7.54692V13.1602H0.0078125V20.6993ZM1.26433 14.4167H6.2904V19.4427H1.26433V14.4167Z"
      :fill="iconColor"
    />
    <path d="M2.51562 15.6719H5.02866V18.1849H2.51562V15.6719Z" :fill="iconColor" />
    <path
      d="M12.5703 0.59375V8.13286H20.1094V0.59375H12.5703ZM18.8529 6.87634H13.8268V1.85027H18.8529V6.87634Z"
      :fill="iconColor"
    />
    <path d="M15.0859 3.10547H17.599V5.6185H15.0859V3.10547Z" :fill="iconColor" />
    <path
      d="M2.52085 9.39062H0.0078125V11.9037H3.77737V10.6471H2.52085V9.39062Z"
      :fill="iconColor"
    />
    <path d="M8.80469 11.9023H11.3177V14.4154H8.80469V11.9023Z" :fill="iconColor" />
    <path d="M3.78125 9.39062H6.29429V10.6471H3.78125V9.39062Z" :fill="iconColor" />
    <path
      d="M11.3177 15.6719H8.80469V16.9284H10.0612V18.1849H11.3177V16.9284V15.6719Z"
      :fill="iconColor"
    />
    <path
      d="M7.54558 9.39062V10.6471H6.28906V11.9037H8.8021V9.39062H7.54558Z"
      :fill="iconColor"
    />
    <path d="M10.0625 5.62109H11.319V8.13413H10.0625V5.62109Z" :fill="iconColor" />
    <path
      d="M11.319 10.6471V11.9037H13.8321V9.39062H10.0625V10.6471H11.319Z"
      :fill="iconColor"
    />
    <path d="M8.80469 8.13281H10.0612V9.38933H8.80469V8.13281Z" :fill="iconColor" />
    <path d="M11.3125 18.1836H13.8255V20.6966H11.3125V18.1836Z" :fill="iconColor" />
    <path d="M8.80469 18.1836H10.0612V20.6966H8.80469V18.1836Z" :fill="iconColor" />
    <path d="M11.3125 14.4141H12.569V15.6706H11.3125V14.4141Z" :fill="iconColor" />
    <path
      d="M11.3177 4.3633V1.85027H10.0612V0.59375H8.80469V5.61982H10.0612V4.3633H11.3177Z"
      :fill="iconColor"
    />
    <path d="M15.0859 18.1836H16.3425V20.6966H15.0859V18.1836Z" :fill="iconColor" />
    <path d="M15.0859 15.6719H17.599V16.9284H15.0859V15.6719Z" :fill="iconColor" />
    <path d="M13.8281 16.9297H15.0846V18.1862H13.8281V16.9297Z" :fill="iconColor" />
    <path d="M12.5703 15.6719H13.8268V16.9284H12.5703V15.6719Z" :fill="iconColor" />
    <path
      d="M17.6016 13.1602V14.4167H18.8581V15.6732H20.1146V13.1602H18.8581H17.6016Z"
      :fill="iconColor"
    />
    <path
      d="M18.8581 16.9297H17.6016V20.6992H20.1146V18.1862H18.8581V16.9297Z"
      :fill="iconColor"
    />
    <path
      d="M12.5703 13.1589V14.4154H16.3399V11.9023H13.8268V13.1589H12.5703Z"
      :fill="iconColor"
    />
    <path
      d="M15.0859 9.39062V10.6471H17.599V11.9037H20.112V9.39062H17.599H15.0859Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>