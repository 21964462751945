<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.933 20.0067L29.9559 8.03698C29.9569 7.50788 29.7477 7.00006 29.3743 6.62522C29.0008 6.25038 28.4938 6.03923 27.9647 6.03822L25.6267 6.03375C24.0402 6.02985 22.5202 5.39622 21.4007 4.27211L19.7481 2.61712C19.3757 2.24324 18.8704 2.03209 18.3427 2.02992L13.6646 2.02098C13.1355 2.02008 12.6278 2.22937 12.253 2.6028L10.5981 4.25146C9.4738 5.37177 7.95057 5.99963 6.36342 5.99694L4.02534 5.99247C3.49624 5.99146 2.98842 6.20067 2.61358 6.57408C2.23874 6.9475 2.02759 7.45452 2.02658 7.98361L2.0037 19.9533C2.00269 20.4824 2.21191 20.9902 2.58532 21.3651C2.95873 21.7399 3.46575 21.9511 3.99484 21.9521L27.9342 21.9978C28.4633 21.9988 28.9712 21.7896 29.346 21.4162C29.7208 21.0428 29.932 20.5358 29.933 20.0067ZM4.02915 3.99752C2.97096 3.9955 1.95531 4.41392 1.20563 5.16075C0.455952 5.90757 0.0336491 6.92161 0.0316271 7.9798L0.00875522 19.9495C0.00673322 21.0077 0.425157 22.0233 1.17198 22.773C1.9188 23.5227 2.93284 23.945 3.99103 23.947L27.9304 23.9928C28.9886 23.9948 30.0043 23.5764 30.7539 22.8295C31.5036 22.0827 31.9259 21.0687 31.9279 20.0105L31.9508 8.04079C31.9528 6.9826 31.5344 5.96695 30.7876 5.21727C30.0408 4.46759 29.0267 4.04529 27.9685 4.04327L25.6305 4.0388C24.5724 4.03655 23.5585 3.6141 22.8118 2.86437L21.1632 1.20939C20.4165 0.459661 19.4027 0.0372113 18.3446 0.0349635L13.6684 0.0260282C12.6103 0.0242323 11.5948 0.442805 10.8453 1.18968L9.19032 2.83834C8.44082 3.58521 7.42533 4.00379 6.36723 4.00199L4.02915 3.99752Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9751 17.9869C17.2978 17.9895 18.5674 17.4664 19.5045 16.5329C20.4416 15.5994 20.9695 14.3318 20.972 13.0091C20.9745 11.6864 20.4515 10.4168 19.518 9.4797C18.5844 8.5426 17.3169 8.01473 15.9941 8.0122C14.6714 8.00967 13.4018 8.5327 12.4647 9.46623C11.5276 10.3998 10.9998 11.6673 10.9972 12.99C10.9947 14.3128 11.5177 15.5823 12.4513 16.5194C13.3848 17.4565 14.6523 17.9844 15.9751 17.9869ZM15.9713 19.9819C17.8231 19.9854 19.6005 19.2532 20.9124 17.9463C22.2244 16.6393 22.9634 14.8647 22.9669 13.0129C22.9705 11.1611 22.2382 9.3837 20.9313 8.07176C19.6244 6.75982 17.8498 6.02079 15.9979 6.01725C14.1461 6.01371 12.3687 6.74595 11.0568 8.05289C9.74485 9.35983 9.00582 11.1344 9.00228 12.9862C8.99874 14.8381 9.73099 16.6154 11.0379 17.9274C12.3449 19.2393 14.1194 19.9784 15.9713 19.9819Z"
      :fill="iconColor"
    />
    <path
      d="M6.01648 8.98957C6.01598 9.25411 5.9104 9.50763 5.72298 9.69433C5.53556 9.88104 5.28165 9.98564 5.0171 9.98514C4.75255 9.98463 4.49904 9.87906 4.31234 9.69164C4.12563 9.50422 4.02103 9.2503 4.02153 8.98576C4.02204 8.72121 4.12761 8.4677 4.31503 8.28099C4.50245 8.09429 4.75637 7.98968 5.02091 7.99019C5.28546 7.99069 5.53897 8.09627 5.72568 8.28369C5.91238 8.47111 6.01699 8.72502 6.01648 8.98957Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>