<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3359 3.33594H15.0026C15.4446 3.33594 15.8686 3.51153 16.1811 3.82409C16.4937 4.13665 16.6693 4.56058 16.6693 5.0026V16.6693C16.6693 17.1113 16.4937 17.5352 16.1811 17.8478C15.8686 18.1603 15.4446 18.3359 15.0026 18.3359H5.0026C4.56058 18.3359 4.13665 18.1603 3.82409 17.8478C3.51153 17.5352 3.33594 17.1113 3.33594 16.6693V5.0026C3.33594 4.56058 3.51153 4.13665 3.82409 3.82409C4.13665 3.51153 4.56058 3.33594 5.0026 3.33594H6.66927"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4974 1.66406H7.4974C7.27638 1.66406 7.06442 1.75186 6.90814 1.90814C6.75186 2.06442 6.66406 2.27638 6.66406 2.4974V4.16406C6.66406 4.38508 6.75186 4.59704 6.90814 4.75332C7.06442 4.9096 7.27638 4.9974 7.4974 4.9974H12.4974C12.7184 4.9974 12.9304 4.9096 13.0867 4.75332C13.2429 4.59704 13.3307 4.38508 13.3307 4.16406V2.4974C13.3307 2.27638 13.2429 2.06442 13.0867 1.90814C12.9304 1.75186 12.7184 1.66406 12.4974 1.66406V1.66406Z"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 10.8307L9.16667 12.4974L12.5 9.16406"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>